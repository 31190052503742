import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Product from "./Product";
import Image from "./Image";
import { useState } from "react";

export default function TabsCustomAnimation({ image, product, orderNow }) {
  const [value, setValue] = useState("Product");
  return (
    <Tabs
      id="custom-animation"
      value="Product"
      className="
      md:w-1/3
        w-full
        bg-slate-100
        rounded-lg
        shadow-lg
      "
      onChange={(value) => {
        setValue(value);
      }}
    >
      <TabsHeader className="w-full  px-4 py-2 rounded-t-lg shadow-lg">
        <Tab
          className="text-sm font-bold
                text-gray-700 hover:text-gray-900
                px-2 py-1 mr-2 rounded-lg
                cursor-pointer transition-all duration-300 ease-in-out
                "
          onClick={() => {
            setValue("Product");
          }}
          value={"Product"}
        >
          Product
        </Tab>
        <Tab
          className="text-sm font-bold
                text-gray-700 hover:text-gray-900
                px-2 py-1 mr-2 rounded-lg
                cursor-pointer transition-all duration-300 ease-in-out
                "
          onClick={() => {
            setValue("Images");
          }}
          value={"Images"}
        >
          Images
        </Tab>
      </TabsHeader>
      <TabsBody
        animate={{
          initial: { y: 250 },
          mount: { y: 0 },
          unmount: { y: 250 },
        }}
        transition={{
          duration: 0.5,
          ease: "easeInOut",
        }}
        defaultValue={"Product"}
      >
        <TabPanel value={"Product"}>
          <Product orderNow={orderNow} product={product} />
        </TabPanel>
        <TabPanel value={"Images"}>
          <Image imageChange={image} />
        </TabPanel>
      </TabsBody>
    </Tabs>
  );
}
