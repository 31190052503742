import moment from "moment";
import React from "react";
import { RiExternalLinkLine } from "react-icons/ri";

function HistoryCard({ History }) {
  return (
    <div className="flex">
      <div className=" px-2 py-8 sm:px-6 sm:py-12 lg:px-8">
        <div className=" ">
          <header className="text-center">
            <h1 className="text-xl font-bold text-gray-900 sm:text-3xl">
              Your Shopping History
            </h1>
          </header>

          <div className="mt-8 w-full min-h-[60vh]">
            <ul className=" flex flex-col gap-5 ">
              {History?.reverse().map((item) => (
                <li
                  key={item?._id}
                  className="flex items-center bg-gray-100  py-2 px-5 rounded-md gap-4"
                >
                  <img
                    src={item?.customImage}
                    alt=""
                    className="size-20 object-cover"
                  />

                  <div>
                    <h3 className="text-xl text-gray-900">
                      {item?.orderItems[0]?.name || item?.additionalInfo}
                    </h3>
                    <h3 className="text-sm text-gray-500">
                      Order ID: {item?._id}
                    </h3>

                    <dl className="mt-0.5 space-y-px text-[12px] text-gray-600">
                      <div>
                        <dd className="inline">
                          {moment(item?.createdAt).fromNow()}
                        </dd>
                      </div>

                      <div>
                        <dt className="inline">Price:</dt>
                        <dd className="inline">
                          NPR. {item?.priceSummary?.grandTotal}
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <div className="flex flex-1 items-center justify-end gap-2">
                    <button
                      onClick={() => {
                        window.open(
                          `https://casemandu.com.np/order/${item?._id}`
                        );
                      }}
                      className="text-gray-600 text-sm flex items-center gap-2 transition hover:text-red-600"
                    >
                      <RiExternalLinkLine size={20} />
                      Track Order
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryCard;
