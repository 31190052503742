import React from 'react'

function Hero() {
  return (
    <div className='h-96 w-full flex justify-center items-center'>
      <div
        className='flex h-full justify-center items-center md:w-10/12 w-full'
        style={{
          backgroundImage: `url('/images/customize.png')`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className='flex flex-col justify-center gap-4 p-5 items-center bg-[#00000086] h-full w-full'
          style={{ backdropFilter: 'blur(4px)' }}
        >
          <h2 className='text-white text-center sm:text-4xl text-xl font-bold'>
            Make your own Design all by Yourself.
          </h2>
          <p className='text-white text-center sm:text-2xl text-md'>
            Select what type you want, select Brand and model and start
            designing.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Hero
