import React from "react";

function Product({ imageUrl, orderNow, price }) {
  return (
    <div className="flex flex-col gap-2">
      <h2 className=" font-bold text-2xl">About the Product</h2>
      <img
        className="h-64 object-contain rounded-xl"
        src={imageUrl}
        alt="Samsung"
      />
      <p className="text-gray-900 text-md">
        Add your own Image, crop it, and move it around to make it look good.
        Design your own.
      </p>
      <div className="flex gap-2">
        <p className="text-gray-600">Price: </p>
        <p className="text-gray-600">
          NPR.
          {price}
        </p>
      </div>

      <div className="flex gap-2">
        <button
          className="
        bg-gray-200
        hover:bg-gray-300
        text-gray-800
        font-semibold
        py-2 px-4
        rounded
        mt-5
        shadow "
          onClick={() => {
            orderNow();
          }}
        >
          Order Now
        </button>
      </div>
    </div>
  );
}

export default Product;
