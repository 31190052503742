import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HistoryCard from "./HistoryCard";

function HistoryMain() {
  const [History, setHistory] = React.useState(
    JSON.parse(localStorage.getItem("orderDetails"))
  );

  useEffect(() => {
    let his = JSON.parse(localStorage.getItem("orderDetails"));
    his = his?.reverse();
    setHistory(his);
    console.log(his);
  }, []);

  const router = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center min-h-[90vh] w-full">
      <div className="flex h-full w-full flex-col items-center justify-center gap-3">
        {!!History ? (
          <HistoryCard History={History} />
        ) : (
          <div className="flex  justify-center items-center w-full h-full">
            <section className="flex items-center justify-center h-full w-full dark:bg-gray-50 dark:text-gray-800">
              <div className=" flex flex-col items-center justify-center px-5  my-8">
                <div className=" text-center">
                  <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-400">
                    <span className="sr-only">Error</span>404
                  </h2>
                  <p className="text-2xl font-semibold md:text-3xl">
                    Your shopping history is empty
                  </p>
                  <p className="mt-4 mb-8 dark:text-gray-600">
                    You haven't made any purchases yet. Start shopping now!
                  </p>
                  <button
                    rel="noopener noreferrer"
                    onClick={() => {
                      router("/");
                    }}
                    className="px-8 py-3 font-semibold rounded dark:bg-violet-600 dark:text-gray-50"
                  >
                    Start shopping
                  </button>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}

export default HistoryMain;
