import React from "react";

function NotFound() {
  return (
    <div className="flex flex-col gap-5 items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl  font-bold text-gray-800">
        404 - Page Not Found
      </h1>
      <p className="text-lg text-gray-600">
        The page you are looking for does not exist{" "}
        <span className="text-primary">
          just like just like your girlfriend 😉 😉
        </span>
      </p>
    </div>
  );
}

export default NotFound;
