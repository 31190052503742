import React from "react";

function Product({ product, orderNow }) {
  return (
    <div className="flex flex-col gap-2">
      <h2 className=" font-bold text-2xl">About the Case</h2>
      <img
        className="h-64 object-contain rounded-xl"
        src={product?.templateImg}
        alt={product?.name}
      />
      <p className="text-gray-900 text-md">
        Add your own Image, crop it, and move it around to make it look good.
        Design your own case.
      </p>
      <div className="flex gap-2">
        <p className="text-gray-600">Model: </p>
        <p className="text-gray-600">{product?.name}</p>
      </div>
      <div className="flex gap-2">
        <p className="text-gray-600">Price: </p>
        <p className="text-gray-600">
          NPR. {Number(product?.price).toLocaleString("en")}
        </p>
      </div>

      <div className="flex gap-2">
        <button
          className="
        bg-gray-200
        hover:bg-gray-300
        text-gray-800
        font-semibold
        py-2 px-4
        rounded
        mt-5
        shadow "
          onClick={() => {
            orderNow();
          }}
        >
          Order Now
        </button>
      </div>
    </div>
  );
}

export default Product;
