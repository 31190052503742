import React from "react";
import { Link } from "react-router-dom";

function SinglePhone({ model }) {
  const rand = Math.floor(Math.random() * 1000);
  const url = "https://picsum.photos/800/1600?random=" + rand;
  const defaultImg = "https://i.ibb.co/9nVHJPv/cmc.png";
  return (
    <Link
      className="flex relative flex-wrap  flex-col items-center gap-4 p-5 cursor-pointer duration-300 hover:bg-blue-gray-50"
      to={`/customize/cases/${model?._id}`}
    >
      <div className="flex relative h-52 w-52 overflow-hidden">
        <img
          src={model?.templateImg}
          alt="img"
          className="h-52 w-52 absolute z-40  object-contain scale-150 top-0 left-0"
        />
        <img
          src={url}
          className="
            h-52 w-52 absolute z-30 object-contain scale-150 top-0 left-0"
          alt=""
          onError={(e) => {
            e.target.src = defaultImg;
          }}
        />
      </div>
      <p className="text-black font-bold text-center">{model?.name}</p>
    </Link>
  );
}

export default SinglePhone;
