import React from "react";

function Image({ imageChange }) {
  const [image, setImage] = React.useState(null);
  React.useEffect(() => {
    imageChange(image);
  }, [image, imageChange]);
  return (
    <div className='flex flex-col gap-3'>
      <div
        className='flex  h-52 w-full border-dashed 
        border-2 border-gray-300 rounded-lg shadow-lg
        hover:bg-gray-200 cursor-pointer justify-center items-center
        '
        onClick={() => {
          document.getElementById("file").click();
        }}
      >
        <p>Upload Image</p>
        <input
          type='file'
          id='file'
          className='hidden'
          onChange={(e) => {
            if (e.target.files[0]) {
              setImage(URL.createObjectURL(e.target.files[0]));
            }
          }}
        />
      </div>
      <div className='flex'>
        <p className='text-sm'>
          By uploading content to{" "}
          <a className='text-blue-800' href='https://casemandu.com.np'>
            Casemandu
          </a>
          , you hereby confirm and declare that your content does not infringe
          any laws or third party rights (such as copyright, trademark right,
          moral right, personality right etc). Any content which is suspected to
          be infringing any laws or third party rights will be removed
          immediately and not proceeded for printing.
        </p>
      </div>
      <div className='flex flex-col gap-3'>
        {image && (
          <img src={image} alt='' className='h-52 w-full object-contain' />
        )}
      </div>
    </div>
  );
}

export default Image;
