import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingCard from "../cards/LoadingCard";

function Phones() {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBrands = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/phones/brands`
      );
      const data = await response.json();
      setLoading(false);
      setBrands(data);
    };
    fetchBrands();
  }, []);

  return (
    <div className="flex flex-col gap-4 items-center">
      <h2 className="text-2xl font-bold text-black pt-8 pb-6 ">
        CUSTOM HARD PHONE CASES
      </h2>
      <div className="h-96 w-full flex justify-center items-center">
        <div
          className="flex h-full justify-center items-center md:w-10/12 w-full"
          style={{
            backgroundImage: `url('/images/customize.png')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            className="flex flex-col justify-center gap-4 p-5 items-center bg-[#00000086] h-full w-full"
            style={{ backdropFilter: "blur(4px)" }}
          >
            <h2 className="text-white text-center sm:text-4xl text-xl font-bold">
              Get your own design all for your mobile case by yourself.
            </h2>
            <p className="text-white text-center sm:text-2xl text-md">
              Select the brand and model of your phone and start designing.
            </p>
          </div>
        </div>
      </div>
      {loading && <LoadingCard msg="Loading Brands" />}
      <div className="min-h-96 md:w-10/12 w-full flex flex-col justify-center items-start border-2 rounded-md mb-10 pt-10 transition-all ">
        <h2 className="text-2xl font-bold text-black p-4">Select Your Brand</h2>
        <div className="w-full flex flex-wrap gap-5 p-5  justify-center md:justify-start">
          {brands?.map((brand, index) => {
            return (
              <Link
                to={`/cases/${brand._id}`}
                key={brand._id}
                className="flex flex-col gap-3 items-center justify-center shadow-xl bg-gray-100 hover:bg-gray-300  duration-300 p-4 rounded-md cursor-pointer"
              >
                <img
                  src={brand.thumbnail}
                  alt={brand.name}
                  className="h-20 w-20  aspect-square object-contain grayscale hover:grayscale-0"
                />
                <p className="text-center text-sm font-bold text-black">
                  {brand.name}
                </p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Phones;
