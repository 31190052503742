import React, {useState} from "react";

function SingleSupport({ options }) {

    const [modelValue, setModelValue] = useState({
        brandName: "",
        modelName: "",
        message: ""
    });


    const [message, setMessage] = useState("");

    function submitForm() {

        if (options === "Request for Model") {
            // check if the user has entered the brand name and model name
            if (!modelValue.brandName || !modelValue.modelName) {
                alert("Please enter the brand name and model name");
                return;
            }
            const newMessage = `Request for Model: ${modelValue.brandName} ${modelValue.modelName} \nMessage: ${modelValue.message}`;
            window.open(`mailto:manducases@gmail.com?subject=Request for Model&body=${newMessage}`);
        } else {
            // check if the user has entered the message
            if (!message) {
                alert("Please enter the message");
                return;
            }
            const newMessage = `${options}: ${message}`;
            window.open(`mailto:manducases@gmail.com?subject=${options}&body=${newMessage}`);
        }
    }

  let inputFields;

  // Render different input fields based on the selected option
  switch (options) {
    case "Request for Model":
      inputFields = (
        <div className="flex flex-col gap-4">
          <label htmlFor="brandName" className="text-sm">
            Brand Name (e.g., Apple, Xiaomi):
          </label>
          <input
            onChange={(e) => {
                setModelValue((prev) => ({
                    ...prev,
                    brandName: e.target.value,
                }));
            }
            }
            type="text"
            id="brandName"
            className="border border-gray-200 bg-transparent rounded-md p-2"
          />
          <label htmlFor="modelName" className="text-sm">
            Model Name: (e.g., iPhone 12, Redmi Note 10):
          </label>
          <input
            type="text"
            onChange={(e) => {
                setModelValue((prev) => ({
                    ...prev,
                    modelName: e.target.value,
                }));
            }
            }
            id="modelName"
            className="border border-gray-200 bg-transparent rounded-md p-2"
          />
           <label htmlFor="modelName" className="text-sm">
            Any message:
          </label>
          <textarea
            onChange={(e) => {
                setModelValue((prev) => ({
                    ...prev,
                    message: e.target.value,
                }));
            }
            }
            className="w-full h-32 border border-gray-200 bg-transparent rounded-md p-2"
            placeholder="Type your message here..."
          ></textarea>
        </div>
      );
      break;
    case "Problem with Ordering":
        inputFields = (
            <textarea
                onChange={(e) => {
                    setMessage(e.target.value);
                }
                }
              className="w-full h-32 border border-gray-200 bg-transparent rounded-md p-2"
              placeholder={`Describe the problem you faced while${options.toLowerCase().replace("with ", "").replace("problem", "")}`}
            ></textarea>
          );
        break;
    case "Problem with Customization":
        inputFields = (
            <textarea
                onChange={(e) => {
                    setMessage(e.target.value);
                }
                }
              className="w-full h-32 border border-gray-200 bg-transparent rounded-md p-2"
              placeholder={`Describe the problem you faced while${options.toLowerCase().replace("with ", "").replace("problem", "")}`}
            ></textarea>
          );
          break
    case "Feedback and Support":
    case "Others":
      inputFields = (
        <textarea
            onChange={(e) => {
                setMessage(e.target.value);
            }
            }
          className="w-full h-32 border border-gray-200 bg-transparent rounded-md p-2"
          placeholder={`Describe the problem you faced while ${options.toLowerCase()}`}
        ></textarea>
      );
      break;
    default:
      inputFields = null;
  }

  return (
    <div className="py-5 flex flex-col gap-4">
      <h2 className="text-lg">{options}?</h2>
      {inputFields}
        <button onClick={submitForm} className="bg-blue-500 text-white p-2 rounded-md">
            Submit
        </button>
    </div>
  );
}

export default SingleSupport;
