import React from 'react'
import { useNavigate } from 'react-router-dom'

const typeData = [
  {
    id: 1,
    name: 'Airpods',
    img: 'https://i.pinimg.com/originals/a7/45/ba/a745ba950094af9f7a7e9370bc6eed13.png',
    link: '/airpods',
  },
  {
    id: 2,
    name: 'Mousepads',
    img: 'https://static.thenounproject.com/png/63390-200.png',
    link: '/mousepads',
  },
  {
    id: 3,
    name: 'Phone Cases',
    img: 'https://cdn2.iconfinder.com/data/icons/device-26/100/01-512.png',
    link: '/cases',
  },
  {
    id: 4,
    name: 'Laptop Sleeves',
    img: 'https://cdn3.iconfinder.com/data/icons/bags-thick-outline/100/Notebook-512.png',
    link: '/laptopsleeves',
  },
  {
    id: 4,
    name: 'Popsockets',
    img: 'https://i.pinimg.com/originals/1c/db/2d/1cdb2d68b9f6451a434b68511e2e3748.png',
    link: '/popsockets',
  },
]

function Select() {
  const route = useNavigate()

  return (
    <div className='min-h-96 w-full flex justify-center items-start'>
      <div className='flex flex-col gap-8 h-full justify-center items-start p-2 md:w-10/12 w-full'>
        <div className='flex justify-center items-center flex-col w-full gap-2 p-5'>
          <h2 className='text-[#000000d7]'>Select the Type</h2>
          <h2 className='text-black sm:text-2xl text-md font-bold'>
            What Do You want to customize?
          </h2>
        </div>
        <div className='flex gap-10 flex-wrap justify-center'>
          {typeData.map((item) => (
            <div
              onClick={() => {
                if (item.id === 3) {
                  route(item.link)
                } else {
                  route('/customize' + item.link)
                }
              }}
              className='flex rounded-lg border-2   hover:bg-white   cursor-pointer border-[#0000007a] sm:w-52 w-80 bg-gray-50 flex-col justify-center items-center gap-2 p-2'
              key={item.id}
            >
              <div className='flex justify-center items-center'>
                <img src={item.img} alt={item.name} className='h-32 w-32' />
              </div>
              <div className='flex justify-center items-center'>
                <h2 className='text-black sm:text-2xl text-md font-bold'>
                  {item.name}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <dialog id="my_modal_3" className="modal text-white">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg">
            Select Model and brand to start Designing
          </h3>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <label htmlFor="brand">Select Brand</label>
              <select
                name="brand"
                id="brand"
                className="border-2 border-[#0000007a] rounded-lg p-2"
                onChange={(e) => {
                  getModel(e.target.value);
                }}
              >
                <option value="">Select Brand</option>
                {brands.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="Model">Select Model</label>
              <select
                name="Model"
                id="Model"
                className="border-2 border-[#0000007a] rounded-lg p-2"
              >
                <option value="">Select Model</option>
                {models.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </dialog> */}
    </div>
  )
}

export default Select
