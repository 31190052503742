import React, { useState } from "react";

const CustomerForm = ({ hideModel }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [form, setForm] = useState({
    shippingAddress: userDetails?.shippingAddress,
    province: userDetails?.province,
    city: userDetails?.city,
    phone: userDetails?.phone,
    name: userDetails?.name,
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !form.name ||
      !form.shippingAddress ||
      !form.province ||
      !form.city ||
      !form.phone
    ) {
      alert("Please fill all the fields");
      return;
    }
    localStorage.setItem("userDetails", JSON.stringify(form));
    hideModel();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-5">
      <div>
        <label className="block">Full Name</label>
        <input
          type="text"
          name="name"
          defaultValue={userDetails?.name}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label className="block">Shipping Address</label>
        <input
          type="text"
          defaultValue={userDetails?.shippingAddress}
          name="shippingAddress"
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label className="block">Province</label>
        <input
          type="text"
          defaultValue={userDetails?.province}
          name="province"
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label className="block">City</label>
        <input
          type="text"
          name="city"
          defaultValue={userDetails?.city}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label className="block">Phone</label>
        <input
          type="text"
          name="phone"
          defaultValue={userDetails?.phone}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>
      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded-md"
      >
        Save
      </button>
    </form>
  );
};

export default CustomerForm;
