import React from "react";
import Editor from "./Editor";
import EditorMenu from "./EditorMenu";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingCard from "../cards/LoadingCard";

function Homepage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  function handleImageChange(e) {
    setImage(e);
  }
  const [imageUrl, setImageUrl] = React.useState(null);
  const [ratio, setRatio] = React.useState(null);

  function orderNow() {
    if (!data.imageSend) {
      alert("Please select the image first");
      return;
    }
    setLoading(true);

    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        // Read the blob as data URL
        const reader = new FileReader();
        reader.onloadend = () => {
          // Get the base64 encoded string
          const base64String = reader.result.split(",")[1];
          uploadToImgbb(base64String);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error("Error fetching the image:", error));
  }
  const api = "e8b696c3aab89c36b9ebdde1c5fc668a";

  function uploadToImgbb(e) {
    const formData = new FormData();
    formData.append("image", e);

    fetch("https://api.imgbb.com/1/upload?key=" + api, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        const imageData = {
          originalImage: res.data?.display_url,
          productId: product._id,
          croppedAreaPixels: data?.croppedAreaPixels,
          product: product,
          type: id,
        };
        localStorage.setItem("imageData", JSON.stringify(imageData));
        navigate("/order");
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (id === "airpods") {
      setRatio(1 / 1);
      setImageUrl("https://i.ibb.co/PDMTbdG/image.png");
      setProduct({
        _id: "60d4c2b6c6a0d6f5a0b6f4f5",
        model: {
          title: "Airpods Customize",
        },
        price: 999,
      });
    } else if (id === "mousepads") {
      setRatio(2 / 1.2);
      setImageUrl("https://i.ibb.co/jhxVBv8/MOUSEPAD-TEMPLETE-UPDATED.png");
      setProduct({
        _id: "60d4c2b6c6a0d6f5a0b6f4f6",
        model: {
          title: "Mousepad Customize",
        },
        price: 1150,
      });
    } else if (id === "laptopsleeves") {
      setRatio(3 / 2.3);
      setImageUrl("https://i.ibb.co/qY1SVXS/laptop.png");
      setProduct({
        _id: "60d4c2b6c6a0d6f5a0b6f4f7",
        model: {
          title: "Laptop Sleeve Customize",
        },
        price: 1150,
      });
    } else if (id === "popsockets") {
      setRatio(1 / 1);
      setImageUrl("https://i.ibb.co/dMxR0Kg/POP-SOCKET-TEPLETE-UPDATED.png");
      setProduct({
        _id: "60d4c2b6c6a0d6f5a0b6f4f8",
        model: {
          title: "Pop Socket Customize",
        },
        price: 150,
      });
    } else {
      navigate("/404");
    }
  }, [id]);

  return (
    <div className="flex md:flex-row flex-col-reverse  w-full p-5">
      {loading && <LoadingCard msg="Making Order" />}
      <Editor
        id={id}
        data={setData}
        ratio={ratio}
        imageUrl={imageUrl}
        image={image}
        price={product?.price}
      />
      <EditorMenu
        price={product?.price}
        orderNow={orderNow}
        imageUrl={imageUrl}
        image={handleImageChange}
      />
    </div>
  );
}

export default Homepage;
